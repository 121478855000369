import { IEnvironment } from './environment.interface';

export const environment: IEnvironment = {
    production: true,
    apiUrl: 'https://preprod-api.earlystage.pl/api/',
    name: 'preprod',
    test: false,
    ESOnlineUrl: 'https://dev-online.earlystage.hslive.co/',
    ESOnlineSecret: 'hs#GV9eC7=s2KA=N',
    GTMTrackingId: 'GTM-KCMZXQZ',
};
